<template>
  <div class="header-navigation" @mouseleave="setCurrentMenuItem(null)">
    <div
      class="sf-header-navigation-item__item sf-header-navigation-item__item--desktop"
    >
      <HeaderNavigationItem
        v-for="(menuItem, index) in menu.items"
        :key="`header-navigation-item-${index}`"
        ref="lvl0ItemRefs"
        :label="menuItem.label"
        :link="$fixUrlForCurrentLocale(menuItem.link)"
        tabindex="1"
        aria-haspopup="true"
        class="nav-item"
        :data-index="index"
        :has-children="hasChildren(menuItem)"
        @mouseenter.native.prevent="setCurrentMenuItem(menuItem)"
        :active="menuItem === currentMenuItem"
      />
    </div>
    <HeaderNavigationSubcategories
      v-for="(menuItem, index) in menu.items"
      :key="`header-navigation-subcategories-${index}`"
      v-show="hasChildren(menuItem) && currentMenuItem === menuItem"
      :menu-item="menuItem"
      @hideSubcategories="setCurrentMenuItem(null)"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import HeaderNavigationItem from './HeaderNavigationItem.vue';
import HeaderNavigationSubcategories from './HeaderNavigationSubcategories.vue';

export default defineComponent({
  name: 'HeaderNavigation',
  components: {
    HeaderNavigationItem,
    HeaderNavigationSubcategories,
  },
  props: {
    menu: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },
  },
  setup() {
    const currentMenuItem = ref(null);

    const setCurrentMenuItem = (menuItem) => {
      currentMenuItem.value = menuItem;
    };

    const hasChildren = (menuItem) => Boolean(menuItem?.children?.length);

    return {
      currentMenuItem,
      setCurrentMenuItem,
      hasChildren,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-navigation {
  display: flex;
  height: 100%;
}
.nav-item {
  --header-navigation-item-margin: 0 var(--spacer-sm);
  @include font-14x16-300;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0;
  &:first-child {
    padding-left: 1rem;
  }
  .sf-header-navigation-item__item--mobile {
    display: none;
  }
}
</style>

<style lang="scss">
.header-navigation {
  .sf-header-navigation-item__item--desktop > *:not(.sf-mega-menu) {
    --header-navigation-item-color: none;
    border: none;
    &:focus {
      --header-navigation-item-color: none;
      border: none;
    }
  }
}
</style>
