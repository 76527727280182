<template>
  <div class="sf-header__icons-mobile">
    <SfButton
      v-e2e="'app-header-search'"
      class="sf-button--pure sf-header__action sf-header__search-image"
      aria-label="Toggle search"
      @click="toggleSearchModal"
    >
      <nuxt-img
        :src="icons.search"
        class="sf-header_menu-image"
        alt="Search"
        width="19"
        height="21"
      />
    </SfButton>
    <SfButton
      v-e2e="'app-header-cart'"
      class="sf-button--pure sf-header__action"
      aria-label="Toggle cart sidebar"
      @click="$emit('cartSidebarClick')"
      :link="$fixUrlForCurrentLocale('/cart')"
    >
      <nuxt-img
        v-if="!cartTotalItems"
        :src="icons.cart"
        class="sf-header_menu-image"
        alt="Cart"
        width="15"
        height="19"
      />
      <SfBadge v-if="cartTotalItems" class="sf-header_menu-image filled-bag">
        {{ cartTotalItems }}
      </SfBadge>
    </SfButton>
  </div>
</template>

<script>
import { SfBadge, SfButton } from '@storefront-ui/vue';
import { useUiState } from '~/composables';

export default {
  name: 'HeaderIconsMobile',
  components: {
    SfBadge,
    SfButton,
  },
  setup() {
    const { toggleSearchModal } = useUiState();

    return {
      toggleSearchModal,
    };
  },
  props: {
    cartTotalItems: {
      type: Number,
    },
    icons: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.sf-header__icons-mobile {
  @include flexbox($placeContent: center, $justifyContent: flex-end);
  .sf-header__action {
    position: relative;
    margin-left: 1.25rem;
    .filled-bag {
      height: 1.5rem;
      width: 1.5rem;
      padding: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 3.125rem;
      background-color: black;
      margin: 0;
    }
  }
  a {
    width: max-content;
  }
  button {
    &.sf-header__search-image {
      height: 3.125rem;
    }
  }
}
</style>
