<template>
  <CustomMegaMenu visible class="mega-menu-mobile">
    <div v-for="item in menu.items" :key="item.uid">
      <CustomMegaMenuColumn :title="item.label" :link="item.link">
        <div class="menu-lv2">
          <div class="menu-lv2__featured" v-if="getItems(item, 'featured')">
            <div v-for="child in getItems(item, 'featured')" :key="child.uid">
              <SfMenuItem
                icon=""
                :link="$fixUrlForCurrentLocale(child.link)"
                class="menu-lv2__links"
              >
                <template #label>
                  {{ child.label.split('---')[0] }}
                  <span
                    class="menu-lv2__links__label"
                    v-if="child.label.split('---')[1]"
                  >
                    {{ child.label.split('---')[1] }}
                  </span>
                </template>
              </SfMenuItem>
            </div>
          </div>
          <div v-for="child in getItems(item, 'items')" :key="child.uid">
            <CustomMegaMenuColumn
              :title="child.label"
              :link="$fixUrlForCurrentLocale(child.link)"
            >
              <div class="menu-lv3">
                <div
                  v-for="childLv2 in child.children"
                  :key="childLv2.uid"
                  class="menu-lv3__links"
                >
                  <SfMenuItem
                    :label="childLv2.label"
                    icon=""
                    :link="$fixUrlForCurrentLocale(childLv2.link)"
                  />
                </div>
              </div>
            </CustomMegaMenuColumn>
          </div>
        </div>
      </CustomMegaMenuColumn>
    </div>
    <SfMenuItem
      :label="$t('Wishlist')"
      icon=""
      :link="$fixUrlForCurrentLocale('wishlist')"
      class="sf-mega-menu-column__header user-links"
    />
    <SfMenuItem
      v-if="supportLink && !loadingBlocks"
      :label="supportLink.label"
      icon=""
      :link="$fixUrlForCurrentLocale(supportLink.url)"
      class="sf-mega-menu-column__header user-links"
    />
    <SfMenuItem
      v-else
      :label="$t('Support')"
      icon=""
      :link="
        $fixUrlForCurrentLocale(
          locale.includes('it') ? '/contatti' : '/contacts'
        )
      "
      class="sf-mega-menu-column__header user-links"
    />
    <SfMenuItem
      :label="isAuthenticated ? $t('Account') : $t('Login')"
      icon=""
      link=""
      @click="handleAccountClick"
      class="sf-mega-menu-column__header user-links"
    />
  </CustomMegaMenu>
</template>

<script>
import {
  computed,
  defineComponent,
  ref,
  shallowRef,
  useContext,
  useFetch,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';
import { SfMenuItem } from '@storefront-ui/vue';
import { contentGetters, useContent, useUser } from '@gemini-vsf/composables';
import { useUiState } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import CustomMegaMenu from './CustomMegaMenu.vue';
import CustomMegaMenuColumn from './CustomMegaMenuColumn.vue';

export default defineComponent({
  name: 'MegaMenu',
  components: {
    CustomMegaMenuColumn,
    CustomMegaMenu,
    SfMenuItem,
  },
  props: {
    menu: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },
  },
  setup() {
    const router = useRouter();
    const { loading: loadingUser, isAuthenticated } = useUser();
    const {
      app,
      $vsf: {
        $gemini: { config },
      },
    } = useContext();
    const { toggleLoginModal } = useUiState();

    const getMegaMenuItems = (menu) => {
      return 'items' in menu ? menu.items : menu.children;
    };

    const hasChildren = (menuItem) => Boolean(menuItem?.children.length > 0);

    const getItems = (menuItem, type) => {
      if (!hasChildren(menuItem)) {
        return false;
      }
      const items = [];
      menuItem.children.forEach((child) => {
        if (type === 'items' && hasChildren(child)) {
          items.push(child);
        } else if (type === 'featured' && !hasChildren(child)) {
          items.push(child);
        }
      });
      return items.length > 0 ? items : false;
    };

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.$fixUrlForCurrentLocale('/my-account'));
      } else {
        toggleLoginModal();
      }
    };

    const { locale } = useI18n();
    const { blocks, loadBlocks } = useContent(`topBar`);
    const topBarSelectors = [`top-bar`, 'markets-association'];
    const supportLink = ref();
    const validGroup = shallowRef();
    const marketsAssociation = ref();
    const userStatus = computed(() => {
      return !loadingUser.value && !isAuthenticated.value ? 'guest' : 'logged';
    });
    const loadingBlocks = shallowRef(true);

    const setValidGroup = (blockData) => {
      marketsAssociation.value = blockData[topBarSelectors[1]];
      Object.keys(marketsAssociation.value).forEach((group) => {
        if (
          group !== 'valid_segments' &&
          marketsAssociation.value?.[group]?.indexOf(
            config?.localesToMarkets?.[locale].split('|')[0]
          ) !== -1
        ) {
          validGroup.value = group;
        }
      });
    };

    const populateBlockData = (blockData) => {
      try {
        const topLevelData =
          blockData[topBarSelectors[0]][
            // fallback to group_0 if there's no validGroup
            // eslint-disable-next-line no-prototype-builtins
            blockData[topBarSelectors[0]].hasOwnProperty(validGroup.value)
              ? validGroup.value
              : 'group_0'
          ][
            // Always use guest data if logged has no value
            blockData[topBarSelectors[0]][validGroup.value]?.logged?.active
              ? userStatus.value
              : 'guest'
          ];
        supportLink.value =
          topLevelData.data?.it?.active && locale === 'it'
            ? topLevelData.data.it.link
            : topLevelData.data.link;
      } catch {
        supportLink.value = {
          text: 'Express Shipping',
          link: {
            label: locale === 'it' ? 'Supporto' : 'Support',
            target: '_self',
            url: locale === 'it' ? '/contatti' : '/contacts',
          },
        };
      }
    };

    useFetch(async () => {
      await loadBlocks({ identifiers: topBarSelectors });
      const blockData = await contentGetters.getCmsBlockContent(blocks.value);
      try {
        // Check all markets array to find in which group the current market is contained
        setValidGroup(blockData);
        if (validGroup.value) {
          populateBlockData(blockData);
        }
        if (process.client) {
          loadingBlocks.value = false;
        } else if (process.server) {
          loadingBlocks.value = true;
        }
      } catch {
        supportLink.value = {
          text: 'Express Shipping',
          link: {
            label: locale === 'it' ? 'Supporto' : 'Support',
            target: '_self',
            url: locale === 'it' ? '/contatti' : '/contacts',
          },
        };
        console.warn('Unable to fetch block content.');
      }
    });

    watch(loadingUser, async () => {
      if (!loadingUser.value) {
        await loadBlocks({ identifiers: topBarSelectors });
        const ssrBlockData = await contentGetters.getCmsBlockContent(
          blocks.value
        );
        setValidGroup(ssrBlockData);
        populateBlockData(ssrBlockData);
        loadingBlocks.value = false;
      }
    });

    return {
      isAuthenticated,
      getMegaMenuItems,
      hasChildren,
      getItems,
      handleAccountClick,
      supportLink,
      locale,
      loadingBlocks,
    };
  },
});
</script>

<style lang="scss">
.mega-menu-mobile {
  &:not(.is-active) {
    background-color: var(--c-super-light-grey);
    .custom-mega-menu__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .sf-mega-menu-column__header,
  .sf-mega-menu__bar {
    height: 4.125rem;
    border-bottom: 1px solid var(--c-grey);
    padding-left: 1.563rem;
    padding-right: 1.563rem;
    background-color: var(--c-white);
    &:hover {
      --menu-item-label-color: inherit;
    }
    &.user-links {
      width: 100vw;
      background-color: var(--c-super-light-grey);
    }
  }
  .sf-menu-item {
    &:hover {
      --menu-item-label-color: inherit;
    }
    &__label {
      @include font-14x16-300;
      color: var(--c-black);
    }
  }
  .menu-lv2 {
    color: var(--c-black);
    &__featured {
      padding: 20px 1.563rem;
      border-bottom: 1px solid var(--c-grey);
    }
    &__links {
      padding: 10px 0;
      @include font-14x16;
      &__label {
        @include font-12x16;
        margin-left: 0.375rem;
        color: var(--c-grey-2);
      }
    }
    .sf-menu-item__label {
      @include font-14x16;
    }
    .menu-lv3 {
      padding: 0.938rem 1.563rem;
      &__links {
        margin: 1.563rem 0;
        .sf-menu-item__label {
          @include font-14x19;
        }
      }
    }
  }
  .sf-mega-menu {
    &__content {
      margin: 0;
    }
    &__bar {
      height: 3.75rem;
      background-color: var(--c-white);
      > div:first-child {
        width: 0;
        .sf-bar__icon {
          width: 0.875rem;
        }
      }
      .sf-bar__title {
        color: var(--c-black);
        @include font-14x16;
        text-transform: uppercase;
      }
    }
    &__menu {
      > div {
        width: 100vw;
      }
    }
  }
}
</style>
