<template>
  <nuxt-link :to="$fixUrlForCurrentLocale('/')" class="sf-header__logo">
    <SfImage
      v-if="logoSrcSVG"
      class="sf-header__logo-img"
      image-tag="nuxt-img"
      :src="logoSrcSVG"
      :alt="logoAlt"
      :title="logoAlt"
      :width="logoWidth"
      :height="logoHeight"
      placeholder=""
    />
    <SvgImage
      v-else
      icon="logo"
      :label="$t('Vue Storefront Next')"
      width="35"
      height="34"
      placeholder=""
    />
  </nuxt-link>
</template>

<script>
import { SfImage } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'HeaderLogo',
  components: { SvgImage, SfImage },
  setup() {
    return {
      logoAlt: 'Biffi',
      logoHeight: 35,
      logoSrc: '/biffi_logo.png',
      logoSrcSVG: '/biffi_logo_svg.svg',
      logoWidth: 81,
    };
  },
});
</script>

<!-- forcing style to avoid bugs on ios -->
<style lang="scss" scoped>
.sf-header__logo {
  margin: 0;
  height: 2.188rem;
  width: 5.0625rem;
  &-img {
    height: 2.188rem;
    width: 5.0625rem;
  }
  @include from-landscape-min {
    height: 3.25rem;
    width: 7.5rem;
    &-img {
      height: 3.25rem;
      width: 7.5rem;
    }
  }
}
</style>

<style lang="scss">
.sf-header__logo {
  &-img {
    .sf-image.sf-image-loaded {
      height: 2.188rem;
      width: 5.0625rem;
      @include from-landscape-min {
        height: 3.25rem;
        width: 7.5rem;
      }
    }
  }
}
</style>
