<template>
  <div class="header-navigation-mobile">
    <div id="open-menu-button">
      <SfButton
        v-e2e="'app-header-menu'"
        class="sf-button--pure sf-header__action"
        data-testid="accountIcon"
        aria-label="Account"
        @click="toggleMenu"
      >
        <nuxt-img
          :src="isMobileMenuOpen ? headerIcons.cross : headerIcons.hamburger"
          class="sf-header_menu-image"
          alt="Menu"
          width="20"
          height="13"
        />
      </SfButton>
    </div>
    <transition name="sf-slide-left">
      <div v-if="isMobileMenuOpen" id="mega_menu">
        <MegaMenuMobile :menu="menu" />
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, ref, useRoute, watch } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import { useUiState, useWindow } from '~/composables';
import MegaMenuMobile from './MegaMenuMobile.vue';

export default defineComponent({
  name: 'HeaderNavigationMobile',
  components: {
    SfButton,
    MegaMenuMobile,
  },
  props: {
    menu: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },
  },
  setup() {
    const { isMobileMenuOpen, toggleMobileMenu } = useUiState();
    const { handleBodyClass } = useWindow();
    const route = useRoute();

    const headerIcons = ref({
      hamburger: '../static/icons/components/hamburger.svg',
      cross: '../static/icons/components/cross.svg',
    });

    const toggleMenu = () => {
      toggleMobileMenu();
      handleBodyClass(
        isMobileMenuOpen.value ? 'addClass' : 'removeClass',
        'open_menu'
      );
    };

    watch(
      () => route.value.fullPath,
      (newValue, oldValue) => {
        if (newValue !== oldValue && isMobileMenuOpen.value) {
          toggleMenu();
        }
      }
    );

    return {
      headerIcons,
      isMobileMenuOpen,
      toggleMenu,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-navigation-mobile {
  position: relative;
  height: 100%;
  width: 100%;
  #open-menu-button {
    display: flex;
    height: 100%;
  }
  #mega_menu {
    position: relative;
    background-color: var(--c-white);
    left: -1.563rem;
    width: 100vw;
    overflow: auto;
    &.sf-slide-left {
      &-enter,
      &-leave {
        &-active,
        &-to {
          transition: all ease-out 300ms;
        }
      }
    }
  }
}
</style>
