<template>
  <div
    v-if="hasChildren(menuItem)"
    data-testid="navigation-subcategories"
    class="header-navigation__subcategories"
  >
    <div class="header-navigation__subcategories-inner">
      <div
        v-if="getItems(menuItem, 'featured')"
        class="header-navigation__subcategory header-navigation__featured"
        aria-haspopup="true"
      >
        <SfList>
          <SfListItem
            v-for="(itemFeat, idxFeat) in getItems(menuItem, 'featured').slice(
              0,
              10
            )"
            :key="idxFeat"
          >
            <SfLink
              :link="$fixUrlForCurrentLocale(itemFeat.link)"
              tabindex="-1"
              @click.native="$emit('hideSubcategories')"
            >
              <div class="header-navigation__linkFeat">
                <span class="header-navigation__linkFeat__main">
                  {{ itemFeat.label.split('---')[0] }}
                </span>
                <span
                  class="header-navigation__linkFeat__label"
                  :class="
                    itemFeat.label.split('---')[1] &&
                    'header-navigation__linkFeat__no-underline'
                  "
                  v-if="itemFeat.label.split('---')[1]"
                >
                  {{ itemFeat.label.split('---')[1] }}
                </span>
              </div>
            </SfLink>
          </SfListItem>
        </SfList>
      </div>
      <div
        v-for="(itemLvl1, idxLvl1) in getItems(menuItem, 'items')"
        :key="idxLvl1"
        class="header-navigation__subcategory"
        aria-haspopup="true"
      >
        <SfLink
          :link="$fixUrlForCurrentLocale(itemLvl1.link)"
          :data-children="itemLvl1.children.length"
          @click.native="$emit('hideSubcategories')"
        >
          <div class="header-navigation__linkCat">{{ itemLvl1.label }}</div>
        </SfLink>
        <SfList v-if="hasChildren(itemLvl1)">
          <SfListItem
            v-for="(itemLvl2, idxLvl2) in getChildrenItems(itemLvl1)"
            :key="idxLvl2"
          >
            <SfLink
              :link="$fixUrlForCurrentLocale(itemLvl2.link)"
              tabindex="-1"
              @click.native="$emit('hideSubcategories')"
            >
              <div
                :class="
                  'header-navigation__linkSubcat' +
                  (itemLvl2.isActive ? ' active' : '')
                "
              >
                {{ itemLvl2.label }}
              </div>
            </SfLink>
          </SfListItem>
        </SfList>
      </div>
    </div>
  </div>
</template>

<script>
import { SfLink, SfList } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HeaderNavigationSubcategories',
  components: {
    SfLink,
    SfList,
  },
  props: {
    menuItem: {
      type: Object,
      default: () => null,
    },
  },
  setup() {
    const hasChildren = (menuItem) => Boolean(menuItem?.children.length > 0);

    const getItems = (menuItem, type) => {
      if (!hasChildren(menuItem)) {
        return false;
      }
      const items = [];
      menuItem.children.forEach((child) => {
        if (type === 'items' && hasChildren(child)) {
          items.push(child);
        } else if (type === 'featured' && !hasChildren(child)) {
          items.push(child);
        }
      });
      return items.length > 0 ? items : false;
    };

    const getChildrenItems = (menuItem) => {
      if (menuItem.children.length < 12) {
        return menuItem.children;
      }
      const cutChildren = menuItem.children.slice(0, 10);
      cutChildren.push({
        isActive: true,
        label: 'View All',
        link: menuItem.link,
      });
      return cutChildren;
    };

    return {
      hasChildren,
      getChildrenItems,
      getItems,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-navigation {
  &__subcategories {
    position: absolute;
    background-color: var(--c-white);
    left: 0;
    right: 0;
    top: 5.875rem;
    transition: top 200ms ease-out;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
    padding: 0 2rem;
    border-bottom: 1px solid var(--c-super-light-grey);
    &-inner {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      max-width: 75rem;
      margin: var(--space-l) auto 0.938rem;
    }
  }
  &__subcategory {
    flex: 1 1 0;
  }
  &__linkFeat {
    @include font-14x16;
    margin-bottom: 1.063rem;
    &__label {
      @include font-12x16;
      margin-left: 0.375rem;
      color: var(--c-grey-2);
    }
  }
  &__linkCat {
    margin-bottom: 0.938rem;
    @include font-14x16;
    text-transform: uppercase;
  }
  &__linkSubcat {
    margin-bottom: 0.938rem;
    @include font-14x19;
    &.active {
      @include font-14x16;
      text-decoration: underline;
    }
  }
}
.sf-header-custom_container.header--sticky {
  .header-navigation__subcategories {
    top: 4.375rem;
  }
}
</style>

<style lang="scss">
.header-navigation__subcategories-inner {
  .sf-link {
    text-decoration: none;
    .header-navigation__linkCat {
      text-decoration: none;
    }
    .header-navigation__linkSubcat {
      text-decoration: none;
    }
    &:hover {
      .header-navigation__linkFeat {
        &__main {
          text-decoration: underline;
        }
        &__label {
          text-decoration: none;
        }
      }
      .header-navigation__linkCat {
        text-decoration: underline;
      }
      .header-navigation__linkSubcat {
        text-decoration: underline;
      }
    }
  }
}
</style>
