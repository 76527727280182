<template>
  <div :class="{ 'is-active': isActive }" class="sf-mega-menu-column">
    <slot
      name="title"
      v-bind="{ title, changeActive: customMegaMenu.changeActive }"
    >
      <SfMenuItem
        class="sf-mega-menu-column__header"
        @click="customMegaMenu.changeActive(title)"
      >
        <template #label>
          <span class="sf-menu-item__label">{{ title }}</span>
        </template>
        <template #mobile-nav-icon>
          <SfIcon
            :class="{ 'display-none': !icon }"
            :icon="icon"
            class="sf-menu-item__mobile-nav-icon smartphone-only"
            size="14px"
          />
        </template>
      </SfMenuItem>
    </slot>

    <div class="sf-mega-menu-column__content">
      <slot />
    </div>
  </div>
</template>

<script>
import { SfMenuItem, SfIcon } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { useUiState, useWindow } from '~/composables';

export default defineComponent({
  name: 'CustomMegaMenuColumn',
  components: {
    SfMenuItem,
    SfIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    icon: {
      type: [String, Array],
      default: 'chevron_right',
    },
  },
  inject: ['customMegaMenu'],
  computed: {
    isActive() {
      return this.customMegaMenu?.active?.includes(this.title);
    },
  },
  setup() {
    const { toggleMobileMenu } = useUiState();
    const { handleBodyClass } = useWindow();

    const openMenuLink = () => {
      handleBodyClass('removeClass', 'open_menu');
      toggleMobileMenu();
    };
    return { openMenuLink };
  },
});
</script>

<style lang="scss">
.sf-mega-menu-column
  .sf-mega-menu-column:not(.is-active)
  .sf-mega-menu-column__content {
  display: none;
}
</style>
