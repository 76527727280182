<template>
  <div class="sf-header__icons-desktop">
    <SfButton
      v-e2e="'app-header-search'"
      class="sf-button--pure sf-header__action sf-header__search-image"
      aria-label="Toggle search"
      @click="toggleSearchModal"
    >
      {{ $t('Search') }}
    </SfButton>
    <SfButton
      v-e2e="'app-header-wishlist'"
      class="sf-button--pure sf-header__action"
      aria-label="Toggle wishlist sidebar"
      @click="handleWishlistClick"
    >
      <div class="header-text-desktop">
        {{ $t('Wishlist') }}
        <p v-if="totalItems">(</p>
        <span v-if="totalItems" class="header-text-desktop-items">
          {{ totalItems }}
        </span>
        <p v-if="totalItems">)</p>
      </div>
    </SfButton>
    <SfButton
      v-e2e="'app-header-cart'"
      class="sf-button--pure sf-header__action"
      aria-label="Toggle cart sidebar"
      @click="$emit('cartSidebarClick')"
    >
      <div class="header-text-desktop">
        {{ $t('Bag') }}
        <p v-if="cartTotalItems">(</p>
        <span v-if="cartTotalItems" class="header-text-desktop-items">
          {{ cartTotalItems }}
        </span>
        <p v-if="cartTotalItems">)</p>
      </div>
    </SfButton>
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import { useContext, useRouter, computed } from '@nuxtjs/composition-api';
import { wishlistGetters } from '@gemini-vsf/composables';
import { useWishlist, useUiState } from '~/composables';

export default {
  name: 'HeaderIconsDesktop',
  components: {
    SfButton,
  },
  props: {
    cartTotalItems: {
      type: Number,
    },
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { toggleSearchModal } = useUiState();
    const { wishlist } = useWishlist();
    const wishlistItems = computed(() =>
      wishlistGetters?.getItems(wishlist?.value)
    );

    const totalItems = computed(() => wishlistItems?.value?.length);
    const handleWishlistClick = async () => {
      await router.push(app.$fixUrlForCurrentLocale('/wishlist'));
    };

    return {
      handleWishlistClick,
      toggleSearchModal,
      wishlistGetters,
      totalItems,
    };
  },
};
</script>

<style lang="scss" scoped>
.sf-header__icons-desktop {
  display: flex;
  @include font-14x16-300;
  text-transform: uppercase;
  .sf-header__action {
    margin: 0 1rem;
    .header-text-desktop {
      display: inline-block;
      &-items {
        font-weight: var(--font-weight--bold);
      }
      & > p {
        display: inherit;
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
  &:hover {
    cursor: pointer;
  }
}
</style>
