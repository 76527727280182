<template>
  <transition :name="transitionName">
    <div
      v-show="visible"
      class="sf-mega-menu custom-mega-menu"
      :class="[{ 'is-active': getActive }, { 'is-absolute': isAbsolute }]"
      :style="megaMenuCustomStyle('main')"
    >
      <div
        class="custom-mega-menu__container"
        :style="megaMenuCustomStyle('container')"
      >
        <SfBar
          v-if="getActive"
          :title="getTitle"
          :back="true"
          class="sf-mega-menu__bar"
          @click:back="back"
        />
        <div class="sf-mega-menu__content">
          <div class="sf-mega-menu__menu">
            <slot />
          </div>
        </div>
        <div v-if="!getActive" class="custom-mega-menu__container__footer">
          <div
            class="custom-mega-menu__container__footer__language"
            @click="toggleCountrySidebar"
          >
            {{ $t('Select your country and language') }}:
            <span class="lang">{{ marketDetails }} </span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { SfBar } from '@storefront-ui/vue';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useUiState } from '~/composables';
import { defineComponent, computed, useRoute } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CustomMegaMenu',
  components: {
    SfBar,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isAbsolute: {
      type: Boolean,
      default: false,
    },
    transitionName: {
      type: String,
      default: 'sf-fade',
    },
  },
  setup() {
    const { toggleCountrySidebar } = useUiState();
    const { locale } = useI18n();
    const route = useRoute();

    const marketDetails = computed(() =>
      route.value?.fullPath.includes('it')
        ? 'IT - Italiano'
        : `${locale.toUpperCase()} - English`
    );

    return {
      toggleCountrySidebar,
      marketDetails,
    };
  },
  data() {
    return {
      active: [],
    };
  },
  provide() {
    const customMegaMenu = {};
    Object.defineProperty(customMegaMenu, 'active', {
      get: () => this.active,
    });
    Object.defineProperty(customMegaMenu, 'changeActive', {
      value: this.changeActive,
    });
    return { customMegaMenu };
  },
  computed: {
    getTitle() {
      return this.active?.length ? this.active[this.active.length - 1] : '';
    },
    getActive() {
      return this.active?.includes(this.getTitle);
    },
  },
  methods: {
    changeActive(payload) {
      this.active.push(payload);
      this.$emit('change', payload);
    },
    back() {
      if (!this.active) {
        this.$emit('close');
      }
      this.active.pop();
    },
    megaMenuCustomStyle(element) {
      const header = document.querySelectorAll(
        '.sf-header-custom_container'
      )[0];
      const headerHeight = Number.parseInt(header.offsetHeight, 10);
      const headerFromTop = Number.parseInt(
        header.getBoundingClientRect().top,
        10
      );
      return element === 'main'
        ? {
            '--mega-menu-content-transform': `translateX(-${
              this.active?.length || '0'
            }00%)`,
            'min-height': `calc(100vh - ${headerHeight + headerFromTop}px)`,
          }
        : {
            'min-height': `${
              window.innerHeight - headerHeight - headerFromTop
            }px`,
          };
    },
  },
});
</script>

<style lang="scss">
@import '@storefront-ui/shared/styles/components/organisms/SfMegaMenu.scss';
</style>

<style lang="scss" scoped>
.custom-mega-menu {
  padding: 0;
  height: auto !important;
  &__container {
    overflow-y: scroll;
    overflow-x: hidden;
    &__footer {
      padding: 2.5rem 1.563rem;
      &__language {
        @include font-12x16;
        font-weight: 500;
        .lang {
          margin-left: 0.625rem;
          font-weight: 300;
        }
      }
    }
  }
}
</style>
